import { Module } from 'vuex'
import { ApplicationState } from '@/store'
import mutations from './mutations'
import actions from './actions'

export interface VideoState {
  videoUrls: Array<string>
}

export const initialState: VideoState = {
  videoUrls: []
}

export function getInitialState (): VideoState {
  return initialState
}

export default {
  namespaced: true,
  state: getInitialState,
  mutations,
  actions
} as Module<VideoState, ApplicationState>
