import { ActionTree } from 'vuex'
import { ApplicationState } from '@/store'
import { PolicyState } from '@/store/modules/policy/index'
import policyDataSource from '@/services/policy-data-source'

export default {
  getPolicyData () {
    return policyDataSource
      .getPolicyData()
      .then((data:any) => {
        return data
      })
  }
} as ActionTree<PolicyState, ApplicationState>
