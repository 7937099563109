import axios from 'axios'
import authenticationService from '@/services/authentication-service'
import { IFeedback } from '@/store/modules/walkthrough/types/feedback/feedback'

export interface IFeedbackDataService {
  send(feedbackInfo: IFeedback, organizationCode: string): Promise<void>
}

export class FeedbackDataService implements IFeedbackDataService {
  async send (feedbackinfo: IFeedback, organizationCode: string): Promise<any> {
    if (!process.env.VUE_APP_FIREBASE_FUNCTIONS_BASE_URL) {
      throw new Error('No base url specified for firebase functions')
    }

    try {
      const token = await authenticationService.getToken()
      const locale = localStorage.getItem('locale') as string
      const payload = buildPayload(feedbackinfo)
      const response = await axios
        .post<unknown>(`${process.env.VUE_APP_FIREBASE_FUNCTIONS_BASE_URL}/api/organizations/${organizationCode}/feedback`, payload, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-type': 'application/json',
            'Accept-Language': locale
          }
        })

      return response
    } catch (error: any) {
      throw new Error(error)
    }
  }
}

function buildPayload (feedbackinfo: IFeedback) {
  return {
    feedback: { ...feedbackinfo }
  }
}
export const instance = new FeedbackDataService()

export default instance
