import { MutationTree } from 'vuex'
import { WalkthroughState } from '@/store/modules/walkthrough/index'
import { TestResultOption } from '@/store/modules/walkthrough/types/types'
import { DeviceCompatible } from '@/store/modules/walkthrough/types/pre-test/deviceCompatible'
import { Passport } from '@/store/modules/walkthrough/types/pre-test/passport'
import { PrePared } from '@/store/modules/walkthrough/types/pre-test/prePared'
import { HandsClean } from '@/store/modules/walkthrough/types/pre-test/handsClean'
import { UserInfo } from '@/store/modules/walkthrough/types/demographic/userInfo'
import { VaccinationState } from '@/store/modules/walkthrough/types/pre-test/vaccinationState'
import { Symptoms } from '@/store/modules/walkthrough/types/pre-test/symptoms'
import { Exposure } from '@/store/modules/walkthrough/types/pre-test/exposure'
import { ConfirmIdentity } from '@/store/modules/walkthrough/types/demographic/confirmIdentity'

export default {

  deviceCompatible (state, payload: DeviceCompatible) {
    state.device_compatible = payload
  },

  passportState (state, payload: Passport) {
    state.passport_state = payload
  },

  preParedState (state, payload: PrePared) {
    state.prepared_state = payload
  },

  handsCleanState (state, payload: HandsClean) {
    state.handsclean_state = payload
  },

  vaccinationState (state, payload: VaccinationState) {
    state.vaccination_state = payload
  },

  symptoms (state, payload: Symptoms) {
    state.symptoms = payload
  },

  userInfo (state, payload: UserInfo) {
    state.user_info = payload
  },

  haveFreeTime (state, payload: boolean) {
    state.have_free_time = payload
  },

  setTestResult (state, payload: string) {
    state.test_result = payload
  },

  setResultSubmitted (state, payload: boolean) {
    state.result_submitted = payload
  },

  confirmIdentity (state, payload: ConfirmIdentity) {
    state.confirm_identity = payload
  },

  exposure (state, payload: Exposure) {
    state.exposure = payload
  },

  selectedOption (state, payload: TestResultOption) {
    state.selected_option = payload
  },

  testCompletionTime (state, payload: Date) {
    state.test_completion_time = payload
  },

  testValidityExpirationTime (state, payload: Date) {
    state.test_validity_expiration_time = payload
  }

} as MutationTree<WalkthroughState>
