import firebaseService from '@/services/firebase-service'
import firebase from 'firebase'
import axios from 'axios'

export interface AuthenticationService {
  authenticate(): Promise<{ userId: string } | null>

  getToken(): Promise<string | null>

  getLocation(): Promise<Array<string>>
}

export class AuthenticationServiceImpl implements AuthenticationService {
  private authentication: firebase.auth.UserCredential | null = null

  async authenticate (): Promise<{ userId: string } | null> {
    const auth = await firebaseService.getAuth()
    const authentication = await auth.signInAnonymously()

    if (!authentication || !authentication.user) {
      return null
    }

    this.authentication = authentication

    return { userId: authentication.user?.uid }
  }

  async getToken (): Promise<string | null> {
    return this.authentication?.user?.getIdToken() || null
  }

  async getLocation (): Promise<Array<string>> {
    try {
      const response = await axios
        .get(`${process.env.VUE_APP_GEO_API_BASE_URL}`, {
          headers: {
            'Content-Type': 'application/json'
          }
        })

      return response.data
    } catch (error:any) {
      return error
    }
  }
}

export const instance = new AuthenticationServiceImpl()

export default instance
