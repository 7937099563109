import { GetterTree } from 'vuex'
import { AuthState } from '@/store/modules/auth/index'
import { ApplicationState } from '@/store'

export default {
  userId: state => state.userId,
  getUserSessionData: state => state.userSessionData,
  userAuthToken: state => state.userAuthToken,
  organizationCode: state => state.organizationCode,
  getEmail: state => state.email,
  getEmailOtp: state => state.emailOtp,
  /**
   * valid organization code are made of 4 groups of 4 digits
   */
  organizationCodeIsValid: state => /^\d{4}-\d{4}-\d{4}-\d{4}$/.test(state.organizationCode),
  loginButtonIsEnabled: (state, getters) => getters.organizationCodeIsValid && !state.authorizationIsOngoing,
  authorizationOutcome: state => state.authorizationOutcome,
  isAuth: state => state.isAuth,
  getOtpVerified: state => state.otpVerified
} as GetterTree<AuthState, ApplicationState>
