






import { Component, Prop, Vue } from 'vue-property-decorator'
import Timer from './Timer.vue'
import CleanPath from '@/helper/clean-path'
@Component({
  components: {
    Timer
  }
})
export default class LineTimer extends Vue {
  private timerStr = ''
  private timerState = 0

  @Prop({ type: String, required: true, default: '' })
  private timeName!: string

  private mounted (): void {
    this.startTimer(Number(sessionStorage.getItem(CleanPath(this.timeName, this.$route.params.name))))
    this.$root.$on('clear-timer', () => {
      clearInterval(this.timerState)
    })
  }

  private startTimer (sec: number): void {
    this.$root.$emit('timer-show')
    this.timerState = setInterval(() => {
      if (sec === 0) {
        this.timerStr = '00:00'
        clearInterval(this.timerState)
        this.$emit('end-time')
        sessionStorage.removeItem(CleanPath(this.timeName, this.$route.params.name))
        this.secondsToHms(0)
      } else {
        this.secondsToHms(sec--)
      }
    }, 1000)
  }

  private secondsToHms (second: number): void {
    sessionStorage.setItem(CleanPath(this.timeName, this.$route.params.name), second.toString())
    let m = Math.floor((second % 3600) / 60).toString()
    let s = Math.floor((second % 3600) % 60).toString()
    if (+s < 10 && s) s = '0' + s.toString()
    if (+m < 10 && m) m = '0' + m.toString()
    this.timerStr = `${m}:${s}`
  }

  private destroyed (): void {
    clearInterval(this.timerState)
    this.$root.$emit('timer-hide')
  }

  private goTo (): void {
    if (sessionStorage.getItem('timer/quidel/2') === 'isRun') {
      const screen = 'Screen_2'
      this.$router.push({ name: 'explanation 2', params: { screen } })
        .catch(() => {
          this.$root.$emit('open-screen', 'Screen_2')
        })
    } else if (sessionStorage.getItem('timer/quidel/4') === 'isRun') {
      const screen = 'Screen_1'
      this.$router.push({ name: 'explanation 4', params: { screen } })
        .catch(() => {
          this.$root.$emit('open-screen', 'Screen_1')
        })
    }
  }
}
