import { ActionTree } from 'vuex'
import { ApplicationState } from '@/store'
import { VideoState } from '@/store/modules/video/index'
import videoURLsDataSource from '@/services/video-urls-data-source'

export default {
  initialize (context): Promise<void> {
    return videoURLsDataSource
      .getVideoURLs()
      .then((videoUrls: Array<string>) => {
        context.commit('setVideoUrls', videoUrls)
      })
  }
} as ActionTree<VideoState, ApplicationState>
