import { Module } from 'vuex'
import { ApplicationState } from '@/store'
import actions from './actions'

export interface TermsState {
  termsData: any
}

export const initialState: TermsState = {
  termsData: null
}

export function getInitialState (): TermsState {
  return initialState
}

export default {
  namespaced: true,
  state: getInitialState,
  actions
} as Module<TermsState, ApplicationState>

export class TermState {
}
