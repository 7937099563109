import { ATestOutcomeHighlight } from '@/store/modules/walkthrough/types/outcomes/resultOverview/highlights/ATestOutcomeHighlight'
import assetLoader from '@/utils/asset-loader'
import { HighlightStatus } from '@/store/modules/walkthrough/types/pre-test/highlightStatus'
import { Exposure } from '@/store/modules/walkthrough/types/pre-test/exposure'

export class ExposureHighlight extends ATestOutcomeHighlight {
  static readonly NO_EXPOSURE = new ExposureHighlight(assetLoader.load('result/thumbs-up-icon.svg'), 'quidel.result.highlights.exposure.not_exposed.title', 'quidel.result.highlights.exposure.not_exposed.description', HighlightStatus.ok)
  static readonly BEEN_EXPOSED = new ExposureHighlight(assetLoader.load('result/exclamation-mark-icon.svg'), 'quidel.result.highlights.exposure.exposed.title', 'quidel.result.highlights.exposure.exposed.description', HighlightStatus.warning)

  // eslint-disable-next-line no-useless-constructor
  private constructor (iconSrc: string, titleTextKey: string, descriptionTextKey: string, status: HighlightStatus) {
    super(iconSrc, titleTextKey, descriptionTextKey, status)
  }

  public static for (exposure: Exposure): ExposureHighlight {
    return exposure.any()
      ? ExposureHighlight.BEEN_EXPOSED
      : ExposureHighlight.NO_EXPOSURE
  }
}
