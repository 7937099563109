import { GetterTree } from 'vuex'
import assetLoader from '@/utils/asset-loader'
import { ApplicationState } from '@/store'
import { WalkthroughState } from '@/store/modules/walkthrough/index'
import {
  TestResultOption
} from '@/store/modules/walkthrough/types/types'
import { TestResult } from '@/store/modules/walkthrough/types/testResult'
import { VaccinationState } from '@/store/modules/walkthrough/types/pre-test/vaccinationState'
import { Symptoms } from '@/store/modules/walkthrough/types/pre-test/symptoms'
import { Exposure } from '@/store/modules/walkthrough/types/pre-test/exposure'
import { OutcomeScenario } from '@/store/modules/walkthrough/types/outcomes/outcomeScenario'
import { DeviceCompatible } from '@/store/modules/walkthrough/types/pre-test/deviceCompatible'
import { Passport } from '@/store/modules/walkthrough/types/pre-test/passport'
import { PrePared } from '@/store/modules/walkthrough/types/pre-test/prePared'
import { HandsClean } from '@/store/modules/walkthrough/types/pre-test/handsClean'
import { UserInfo } from '@/store/modules/walkthrough/types/demographic/userInfo'
import { ConfirmIdentity } from '@/store/modules/walkthrough/types/demographic/confirmIdentity'

export default {
  testResultOptions: (): TestResultOption[] => {
    return [
      {
        result: TestResult.Positive,
        iconSrc: assetLoader.load('input-result-options/result-01.svg')
      },
      {
        result: TestResult.Positive,
        iconSrc: assetLoader.load('input-result-options/result-02.svg')
      },
      {
        result: TestResult.Negative,
        iconSrc: assetLoader.load('input-result-options/result-03.svg')
      },
      {
        result: TestResult.Inconclusive,
        iconSrc: assetLoader.load('input-result-options/result-04.svg')
      },
      {
        result: TestResult.Inconclusive,
        iconSrc: assetLoader.load('input-result-options/result-05.svg')
      }
    ]
  },

  selectedOption: (state): TestResultOption | null => {
    return state.selected_option
  },

  deviceCompatible: (state): DeviceCompatible | null => {
    return state.device_compatible
  },

  passportState: (state): Passport | null => {
    return state.passport_state
  },

  PreParedState: (state): PrePared | null => {
    return state.prepared_state
  },

  HandsCleanState: (state): HandsClean | null => {
    return state.handsclean_state
  },

  vaccinationState: (state): VaccinationState | null => {
    return state.vaccination_state
  },

  symptoms: (state): Symptoms | null => {
    return state.symptoms
  },

  userInfo: (state): UserInfo | null => {
    return state.user_info
  },

  getHaveFreeTime: (state): boolean => {
    return state.have_free_time
  },

  confirmIdentity: (state): ConfirmIdentity | null => {
    return state.confirm_identity
  },

  haveSymptoms: (state, getters): boolean | null => {
    let haveSymptoms = false
    const symptoms = getters.symptoms
    for (const prop in symptoms) {
      if (!Object.prototype.hasOwnProperty.call(symptoms, prop)) {
        continue
      }
      haveSymptoms = haveSymptoms || !!symptoms[prop]
    }
    return haveSymptoms
  },

  exposure: (state): Exposure | null => {
    return state.exposure
  },

  beenExposed: (state, getters): boolean | null => {
    let haveBeenExposed = false
    const exposure = getters.exposure
    for (const prop in exposure) {
      if (!Object.prototype.hasOwnProperty.call(exposure, prop)) {
        continue
      }
      haveBeenExposed = haveBeenExposed || !!exposure[prop]
    }
    return haveBeenExposed
  },

  outcomeScenario: (state, getters): OutcomeScenario | null => {
    const selectedOption = getters.selectedOption
    if (selectedOption == null) return null
    const testResult = selectedOption.result

    const deviceCompatible = getters.deviceCompatible
    if (deviceCompatible == null) return null

    const passport = getters.passportState
    if (passport == null) return null

    const vaccinationState = getters.vaccinationState
    if (vaccinationState == null) return null

    const symptoms = getters.symptoms
    if (symptoms == null) return null

    const exposure = getters.exposure
    if (exposure == null) return null

    return OutcomeScenario.getMatchingScenario(vaccinationState, symptoms, exposure, testResult)
  },

  testCompletionTime: (state): Date | null => {
    return state.test_completion_time
  },

  testResultsExpirationTime: (state): Date | null => {
    return state.test_validity_expiration_time
  }
} as GetterTree<WalkthroughState, ApplicationState>
