import firebaseService from './firebase-service'

export declare type VideoURLs = string[]

export interface IVideoURLsDataSource {
  getVideoURLs(): Promise<VideoURLs>
}

export class RemoteConfigVideoURLsDataSource implements IVideoURLsDataSource {
  getVideoURLs (): Promise<VideoURLs> {
    return firebaseService.getRemoteConfig().then((remoteConfig) => {
      return remoteConfig.fetchAndActivate().then(() => {
        let configurationValue
        const locale = localStorage.getItem('locale') as string
        if (locale === 'es') { configurationValue = remoteConfig.getString('bh_qvue_travel_video_es') } else { configurationValue = remoteConfig.getString('bh_qvue_travel_video_en') }
        try {
          return JSON.parse(configurationValue)
        } catch (e) {
          console.log(e)
        }
      })
    })
  }
}

export const instance = new RemoteConfigVideoURLsDataSource()
export default instance
