











































import { Component, Prop, Vue } from 'vue-property-decorator'
import CleanPath from '@/helper/clean-path'

const strokeDasharray = 770// full circle
@Component({})
export default class Timer extends Vue {
  private timerStr = '10:00'
  private percentage = 0
  @Prop({ type: Number, required: true })
  private defTime!: number

  @Prop({ type: Number, required: true, default: 0 })
  private initTime!: number

  @Prop({ type: Boolean, required: false, default: false })
  private manualRun!: boolean

  private timerState = 0

  public clearTimer (): void {
    clearInterval(this.timerState)
  }

  public startTimer (sec: number): void {
    sessionStorage.setItem('countdown-time' + CleanPath(this.$route.path, this.$route.params.name), this.initTime.toString())
    clearInterval(this.timerState)
    this.timerState = setInterval(() => {
      if (sec === 0) {
        this.timerStr = '00:00'
        clearInterval(this.timerState)
        this.$emit('end-time')
        sessionStorage.removeItem('time' + CleanPath(this.$route.path, this.$route.params.name))
        this.secondsToHms(0)
      } else {
        this.secondsToHms(sec--)
      }
    }, 1000)
  }

  private secondsToHms (d: number): void {
    const t = Number(sessionStorage.getItem('countdown-time' + CleanPath(this.$route.path, this.$route.params.name)))
    sessionStorage.setItem('time' + CleanPath(this.$route.path, this.$route.params.name), d.toString())
    this.percentage = strokeDasharray - d / (t / strokeDasharray)
    let m = Math.floor((d % 3600) / 60).toString()
    let s = Math.floor((d % 3600) % 60).toString()
    if (+s < 10 && s) s = '0' + s.toString()
    if (+m < 10 && m) m = '0' + m.toString()
    else m = m.toString()
    this.timerStr = `${m}:${s}`
  }

  private created (): void {
    if (!this.manualRun || sessionStorage.getItem('timerFlag')) {
      if (!this.timerState) this.startTimer(this.defTime)
    }
  }

  private destroyed (): void {
    clearInterval(this.timerState)
  }
}
