import { ATestOutcomeHighlight } from '@/store/modules/walkthrough/types/outcomes/resultOverview/highlights/ATestOutcomeHighlight'
import assetLoader from '@/utils/asset-loader'
import { HighlightStatus } from '@/store/modules/walkthrough/types/pre-test/highlightStatus'
import { VaccinationState } from '@/store/modules/walkthrough/types/pre-test/vaccinationState'

export class VaccinationStateHighlight extends ATestOutcomeHighlight {
  static readonly FULLY_VACCINATED = new VaccinationStateHighlight(assetLoader.load('result/thumbs-up-icon.svg'), 'quidel.result.highlights.vaccination.fully_vaccinated.title', 'quidel.result.highlights.vaccination.fully_vaccinated.description', HighlightStatus.ok)
  static readonly NOT_FULLY_VACCINATED = new VaccinationStateHighlight(assetLoader.load('result/exclamation-mark-icon.svg'), 'quidel.result.highlights.vaccination.not_fully_vaccinated.title', 'quidel.result.highlights.vaccination.not_fully_vaccinated.description', HighlightStatus.warning)

  // eslint-disable-next-line no-useless-constructor
  private constructor (iconSrc: string, titleTextKey: string, descriptionTextKey: string, status: HighlightStatus) {
    super(iconSrc, titleTextKey, descriptionTextKey, status)
  }

  public static for (vaccinationState: VaccinationState): VaccinationStateHighlight {
    return vaccinationState === VaccinationState.FullyVaccinated
      ? VaccinationStateHighlight.FULLY_VACCINATED
      : VaccinationStateHighlight.NOT_FULLY_VACCINATED
  }
}
