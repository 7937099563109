import { ActionTree } from 'vuex'
import { WalkthroughState } from '@/store/modules/walkthrough/index'
import moment from 'moment'
import { ApplicationState } from '@/store'
import { TestResultOption } from '@/store/modules/walkthrough/types/types'
import { VaccinationState } from '@/store/modules/walkthrough/types/pre-test/vaccinationState'
import { DeviceCompatible } from '@/store/modules/walkthrough/types/pre-test/deviceCompatible'
import { Passport } from '@/store/modules/walkthrough/types/pre-test/passport'
import { PrePared } from '@/store/modules/walkthrough/types/pre-test/prePared'
import { HandsClean } from '@/store/modules/walkthrough/types/pre-test/handsClean'
import { UserInfo } from '@/store/modules/walkthrough/types/demographic/userInfo'
import { Symptoms } from '@/store/modules/walkthrough/types/pre-test/symptoms'
import { Exposure } from '@/store/modules/walkthrough/types/pre-test/exposure'
import statisticalDataService from '@/services/statistical-data-service'
import { ConfirmIdentity } from '@/store/modules/walkthrough/types/demographic/confirmIdentity'
import firebaseUserDataStore from '@/services/user-data-store'
import feedbackDataService from '@/services/feedback-data-service'
import { IFeedback } from '@/store/modules/walkthrough/types/feedback/feedback'
import { IEmailReminder } from './types/demographic/emailReminder'
import emailReminderDataService from '@/services/email-reminder-data-service'
export default {

  setDeviceCompatible (context, payload: DeviceCompatible) {
    context.commit('deviceCompatible', payload)
  },

  setPassport (context, payload: Passport) {
    context.commit('passportState', payload)
  },

  setPrePared (context, payload: PrePared) {
    context.commit('preParedState', payload)
  },

  setHandsClean (context, payload: HandsClean) {
    context.commit('handsCleanState', payload)
  },

  setVaccinationState (context, payload: VaccinationState) {
    context.commit('vaccinationState', payload)
  },

  setSymptoms (context, payload: Symptoms) {
    context.commit('symptoms', payload)
  },

  async setUserInfo (context, payload: UserInfo) {
    context.commit('userInfo', payload)
    const userId = context.rootGetters['auth/userId']
    const data = {
      ...payload
    }
    await firebaseUserDataStore.writeUserData(userId, data)
  },

  setConfirmIdentity (context, payload: ConfirmIdentity) {
    context.commit('confirmIdentity', payload)
  },

  setExposure (context, payload: Exposure) {
    context.commit('exposure', payload)
  },

  setSelectedOption (context, payload: TestResultOption) {
    context.commit('selectedOption', payload)
  },

  setHaveFreeTime (context, payload: boolean) {
    context.commit('haveFreeTime', payload)
  },

  setFeedbackInfo (context, payload: IFeedback) {
    context.commit('feedbackinfo', payload)
  },

  resetResultSubmit (context) {
    context.commit('setTestResult', '')
    context.commit('setResultSubmitted', false)
  },

  async sendResult (context, payload: { testCompletionTime: Date, testValidityExpirationTime: Date, result: string }) {
    const userId = context.rootGetters['auth/userId']
    const email = localStorage.getItem('newEmail') as string
    // const location = localStorage.getItem('selectedLocation') as string

    const organizationCode = localStorage.getItem('organizationCode') as string
    const userInfo = context.getters.userInfo

    const data = {
      demographics: {
        email: email,
        full_name: userInfo.fullName,
        date_of_flight: userInfo.flyingDate,
        passport_number: userInfo.passportNumber
      },
      health_check: {},
      test: {
        test_result: parseInt(payload.result.slice(-1)),
        completed_at: payload.testCompletionTime,
        valid_until: payload.testValidityExpirationTime,
        current_date_localized: moment(payload.testCompletionTime).format('MM/DD/YYYY'),
        valid_until_localized: moment(payload.testValidityExpirationTime).format('MM/DD/YYYY')
      }
    }

    const previousResult = {
      previousResult: {
        email: email,
        full_name: userInfo.fullName,
        date_of_flight: userInfo.flyingDate,
        passport_number: userInfo.passportNumber,
        test_result: btoa((payload.result.slice(-1))),
        completed_at: payload.testCompletionTime,
        completed_at_time: moment(payload.testCompletionTime).format('h:mm A'),
        valid_until: payload.testValidityExpirationTime,
        current_date_localized: moment(payload.testCompletionTime).format('MM/DD/YYYY'),
        valid_until_localized: moment(payload.testValidityExpirationTime).format('MM/DD/YYYY')
      }
    }

    await firebaseUserDataStore.writeUserData(userId, data)
    await firebaseUserDataStore.writeUserData(userId, previousResult)

    await statisticalDataService.send(
      userId,
      organizationCode,
      payload.testCompletionTime,
      payload.testValidityExpirationTime,
      userInfo,
      payload.result,
      email
    )
  },

  async sendDemographic (context) {
    const userId = context.rootGetters['auth/userId']

    const userInfo = context.getters.userInfo

    // await demographicDataService.send(
    //   userInfo,
    //   organizationCode
    // )
    const data = {
      ...userInfo,
      is_demographic_filled: true
    }
    await firebaseUserDataStore.writeUserData(userId, data)
    const newFormatdata = {
      data: {
        demographics: {
          full_name: userInfo.fullName,
          date_of_flight: userInfo.flyingDate,
          passport_number: userInfo.passportNumber
        }
      }
    }

    await firebaseUserDataStore.writeUserData(userId, newFormatdata)
  },

  async completeTest (context, result:string) {
    const testCompletionTime = new Date()
    const testValidityExpirationTime = new Date(testCompletionTime.valueOf())
    testValidityExpirationTime.setTime(testValidityExpirationTime.getTime() + (context.state.test_validity_duration_in_hours * 60 * 60 * 1000))
    context.commit('testCompletionTime', testCompletionTime)
    context.commit('testValidityExpirationTime', testValidityExpirationTime)
    if (context.state.test_result !== result) {
      context.commit('setTestResult', result)
      context.commit('setResultSubmitted', true)
      await context
        .dispatch('sendResult', { testCompletionTime, testValidityExpirationTime, result })
    }
  },

  async sendFeedback (context, feedbackInfo:IFeedback) {
    const organizationCode = context.rootGetters['auth/organizationCode']
    try {
      const response = await feedbackDataService.send(
        feedbackInfo,
        organizationCode
      )
      return response
    } catch (err:any) {
      throw new Error(err)
    }
  },

  async sendMeReminder (context, emailReminder:IEmailReminder) {
    const organizationCode = context.rootGetters['auth/organizationCode']
    try {
      const response = await emailReminderDataService.send(
        emailReminder,
        organizationCode
      )
      return response
    } catch (err:any) {
      throw new Error(err)
    }
  }

} as ActionTree<WalkthroughState, ApplicationState>
