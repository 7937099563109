import { TestOutcomeHighlight } from '@/store/modules/walkthrough/types/outcomes/resultOverview/highlights/testOutcomeHighlight'
import { HighlightStatus } from '@/store/modules/walkthrough/types/pre-test/highlightStatus'

export class ATestOutcomeHighlight implements TestOutcomeHighlight {
  // eslint-disable-next-line no-useless-constructor
  protected constructor (
    public readonly iconSrc: string,
    public readonly titleTextKey: string,
    public readonly descriptionTextKey: string,
    public readonly status: HighlightStatus
  ) {}
}
