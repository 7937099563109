



































import { Component, Vue } from 'vue-property-decorator'
import Logo from '@/components/Logo.vue'
import MobileNav from '@/components/MobileNav.vue'
import store from '@/store'
import html2canvas from 'html2canvas'
import router from '@/router'
@Component({
  components: {
    Logo,
    MobileNav
  }
})

export default class Header extends Vue {
  startTheTestButtonVisible = false;
  bellaMissionButtonVisible = false;
  private isMobileNav = false
  private previousResult = false
  private isShowLogo = false
  created (): void {
    // this.$store.dispatch('auth/setIsAuth', true)

    store.watch(
      (state, getters) => getters['auth/hasLoggedIn'],
      (hasLoggedIn): void => {
        this.startTheTestButtonVisible = hasLoggedIn
        this.bellaMissionButtonVisible = hasLoggedIn
      }
    )
    this.$root.$on('logo', (state: boolean) => {
      this.isShowLogo = state
    })
  }

  public mobileGoBack ():void {
    // This is logic is not working for back()
    // this.$root.$emit('mobile-go-back')
    if (this.$route.name === 'language') {
      this.$router.push({ name: 'splash' })
    } else if (this.$route.name === 'outcome') {
      this.$router.push({ name: 'test in progress' })
    } else { this.$router.back() }
  }

  get isAuth (): boolean {
    return this.$store.getters['auth/isAuth']
  }

  get isDemographyFilled ():boolean {
    this.$store.dispatch('walkthrough/resetResultSubmit')
    const data = this.$store.getters['auth/getUserSessionData']
    if (Object.prototype.hasOwnProperty.call(data, 'is_demographic_filled')) {
      if (data.is_demographic_filled) {
        return true
      }
    }
    return false
  }

  private openNav (): void {
    const video = document.querySelector('video')
    if (video) video.pause()
    this.isMobileNav = true
    const result = localStorage.getItem('testResult') as string
    if (result !== '') {
      this.previousResult = true
    } else {
      this.previousResult = false
    }
  }

  private closeNav (): void {
    const video = document.querySelector('video')
    if (video) video.play()
    this.isMobileNav = false
  }

  private goToWeb ():void {
    if (this.isAuth) {
      const result = localStorage.getItem('testResult') as string
      const locale = localStorage.getItem('locale') as string
      if (result !== undefined || result !== '' || result !== null) {
        var data = atob(result)
        console.log('test_result', data)
        if (data === 'test_result=2' || data === 'test_result=1') {
          this.$router.push('/' + locale + '/previous-result/2')
        } else if (data === 'test_result=3') {
          this.$router.push('/' + locale + '/previous-result/1')
        } else if (data === 'test_result=4' || data === 'test_result=5') {
          this.$router.push('/' + locale + '/previous-result/3')
        }
      } else if (this.isDemographyFilled) {
        this.$router.push({ name: 'pre test video' })
      } else this.$router.push({ name: 'demographic welcome' })
    } else this.$router.push({ name: 'splash' })
  }

  private async share () : Promise<void> {
    const nav:any = navigator
    if (!('canShare' in nav)) {
      console.warn('Sharing not supported in your browser.')
      this.download()
      return
    }

    const title: any = document.querySelector('.d-flex.align-items-center h1')
    const canvas: any = await html2canvas(document.querySelector('.outcome-component-content') as any)

    canvas.toBlob(async (blob : any) => {
      const files = [new File([blob], 'QVueResult.png', { type: blob.type })]
      const shareData = {
        text: '',
        title: '',
        files
      }
      if (nav.canShare(shareData)) {
        try {
          await nav.share(shareData)
        } catch (err:any) {
          if (err.name !== 'AbortError') {
            console.error(err.name, err.message)
          }
          this.download()
        }
      } else {
        console.warn('Sharing not supported', shareData)
      }
    })
  }

  private download () :void {
    window.print()
  }
}
