import axios from 'axios'
import authenticationService from '@/services/authentication-service'

export interface VerifyEmailOtpService {
  send(email: string, code: string): Promise<boolean>;
}

export class FirebaseVerifyEmailOtpService implements VerifyEmailOtpService {
  async send (email: string, code: string): Promise<boolean> {
    if (!process.env.VUE_APP_FIREBASE_FUNCTIONS_BASE_URL) {
      throw new Error('No base url specified for firebase functions')
    }

    try {
      const token = await authenticationService.getToken()
      const ctu = localStorage.getItem('ctu') as string
      const payload = buildPayload(email, code)
      const response = await axios
        .post<unknown>(`${process.env.VUE_APP_FIREBASE_FUNCTIONS_BASE_URL}/api/users/auth/validate_code`, payload, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-type': 'application/json',
            'Accept-Language': ctu
          }
        })

      return true
    } catch (error) {
      console.error('[FirebaseVerifyEmailOtpService] send failed', error)
      return false
    }
  }
}
function buildPayload (email: string, code: string) {
  return { email, code }
}
export const instance = new FirebaseVerifyEmailOtpService()

export default instance
