import { ActionTree } from 'vuex'
import { AuthState } from '@/store/modules/auth/index'
import { ApplicationState } from '@/store'
import organizationAuthorizationService, { AuthorizationOutcome } from '@/services/organization-authorization-service'
import authenticationService from '@/services/authentication-service'
import firebaseUserDataStore from '@/services/user-data-store'
import sentEmailOtp from '@/services/sent-email-otp-service'
import verifyEmail from '@/services/verify-email-otp-service'
import firebaseService from '@/services/firebase-service'

import { v4 as uuidv4 } from 'uuid'
export default {
  async init (context): Promise<void> {
    const authResponse = await authenticationService.authenticate()
    if (!authResponse) {
      return
    }
    context.commit('userId', authResponse.userId)
    localStorage.setItem('userId', authResponse.userId)
    const userResponse = await firebaseUserDataStore.getUserData(authResponse.userId)
    if (userResponse === null) {
      return
    }
    context.commit('setUserData', userResponse)
  },
  async userDataStore (context, data): Promise<void> {
    switch (data) {
      case 'organizationCode':
        context.commit('organizationCode', context.state.userSessionData.organization_code)
        break
      case 'setEmail':
        context.commit('setEmail', context.state.userSessionData.email)
        break
      case 'setAuth':
        context.commit('setAuth', true)
        break
      default:
        break
    }
  },
  async setUpdatedOrganizationCode (context, organizationCode): Promise<void> {
    context.commit('organizationCode', organizationCode)
    context.commit('authorizationIsOngoing', false)
    context.commit('authorizationOutcome', null)
    context.commit('authorizationError', null)

    const data = {
      organization_code: organizationCode
    }
    await firebaseUserDataStore.writeUserData(context.state.userId, data)
    const userResponse = await firebaseUserDataStore.getUserData(context.state.userId)
    if (userResponse === null) {
      return
    }
    context.commit('setUserData', userResponse)
  },
  async setDemographic (context, isField: boolean) {
    const data = {
      is_demographic_filled: isField
    }
    await firebaseUserDataStore.writeUserData(context.state.userId, data)
    const userResponse = await firebaseUserDataStore.getUserData(context.state.userId)
    if (userResponse === null) {
      return
    }
    context.commit('setUserData', userResponse)
  },
  async setIsAuth (context, auth): Promise<boolean> {
    context.commit('setAuth', auth)
    localStorage.setItem('isAuth', auth.toString())
    try {
      const data = {
        organization_code: context.state.userSessionData.organization_code,
        email: context.state.email
      }

      localStorage.setItem('organizationCode', data.organization_code)
      localStorage.setItem('email', data.email)

      await firebaseUserDataStore.writeUserData(context.state.userId, data)
      return true
    } catch (error) {
      return false
    }
  },
  async fetchGeolocation (context): Promise<void> {
    const locationResponse:any = await authenticationService.getLocation()
    if (locationResponse.status === 'ok') {
      const data = {
        geoInfo: locationResponse.data
      }
      await firebaseUserDataStore.writeUserData(context.state.userId, data)
      const userResponse = await firebaseUserDataStore.getUserData(context.state.userId)
      if (userResponse === null) {
        return
      }
      context.commit('setUserData', userResponse)
      return locationResponse.data
    }
  },
  async getUserData (context): Promise<void> {
    return await firebaseUserDataStore.getUserData(context.state.userId)
  },
  async getTestData (context): Promise<void> {
    const ctu = localStorage.getItem('ctu') as string
    return await firebaseUserDataStore.getResultData(ctu)
    // console.log('user', userResponse)
  },
  async deleteResultData (): Promise<void> {
    const ctu = localStorage.getItem('ctu') as string
    console.log('QVUE: Delete ctu from firebase', ctu)

    if (ctu !== null) {
      return await firebaseUserDataStore.deleteResultData(ctu)
    }
    // console.log('user', userResponse)
  },
  async setResultData (): Promise<void> {
    const ctu = uuidv4()
    localStorage.setItem('ctu', ctu)
    console.log('QVUE: New ctu added', ctu)
    const data = 'null'
    return await firebaseUserDataStore.writeResultData(ctu, data)
    // console.log('user', userResponse)
  },
  async unsubscribeFirebase (): Promise<void> {
    return await firebaseService.databaseConnection().then((db) => {
      const ctu = localStorage.getItem('ctu') as string
      return db.ref('qvue_travel_temp/' + ctu).off('value')
    })
    // console.log('user', userResponse)
  },
  async getGeolocation (context): Promise<void> {
    return await context.state.userSessionData.geoInfo
  },
  async authorize (context): Promise<void> {
    context.commit('authorizationIsOngoing', true)

    try {
      const authorizationOutcome = await organizationAuthorizationService
        .authorize(context.state.userSessionData.organization_code)

      context.commit('authorizationOutcome', authorizationOutcome)
    } catch (error) {
      context.commit('authorizationOutcome', AuthorizationOutcome.error)
      context.commit('authorizationError', error)
    } finally {
      context.commit('authorizationIsOngoing', false)
    }
  },
  async emailOtpSent (context, data): Promise<boolean> {
    context.commit('setEmail', data.email)
    try {
      const responseSentEmailOtp = await sentEmailOtp.send(data.email)
      return responseSentEmailOtp
    } catch (error) {
      return false
    }
  },
  async verifyEmailOtpSent (context, data): Promise<boolean> {
    context.commit('setEmailOtp', data.code)
    try {
      const responseverifyEmailOtpSent = await verifyEmail.send(data.email, data.code)
      if (responseverifyEmailOtpSent) {
        context.commit('setEmailOtpVerified', true)
        return true
      } else {
        context.commit('setEmailOtpVerified', false)
        return false
      }
    } catch (error) {
      return false
    }
  }
} as ActionTree<AuthState, ApplicationState>
