import store from '@/store'
import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import { AuthorizationOutcome } from '@/services/organization-authorization-service'
import i18n from '../i18n'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '',
    // beforeEnter: (to, from, next) => {
    //   const locale = to.query.locale as string
    //   console.log('locale', locale)
    //   localStorage.setItem('locale', locale)
    //   store.dispatch('walkthrough/setLang', locale)
    //   store.dispatch('video/initialize', locale)
    //   const supportedLocales = process.env.VUE_APP_I18N_FALLBACK_LOCALE.split(',')
    //   console.log('Invalid locale', supportedLocales)
    //   if (!supportedLocales.includes(locale)) {
    //     return next(process.env.VUE_APP_I18N_LOCALE)
    //   }
    //   if (i18n.locale !== locale) {
    //     i18n.locale = locale
    //   }
    //   return next({ path: locale, query: { serial_number: to.query.serial_number as string, case_id: to.query.case_id as string, redirect_url: to.query.redirect_url as string, locale: locale } })
    // }
    redirect: { path: '/' + process.env.VUE_APP_I18N_LOCALE }
  },
  {
    path: '/signup/organization',
    beforeEnter: (to, from, next) => {
      // const locale = to.query.locale as string
      // localStorage.setItem('locale', locale)
      // if (locale !== null) {
      //   store.dispatch('video/initialize')
      // }
      const orgCodeFromQuerystring = to.query.organization_code as string
      if (orgCodeFromQuerystring) {
        localStorage.setItem('organizationCode', orgCodeFromQuerystring)
        store.dispatch('auth/setUpdatedOrganizationCode', orgCodeFromQuerystring)
        // store.dispatch('auth/authorize')
      }
      next({ path: 'en' })
    },
    alias: '/signup',
    name: 'sign up'
    // redirect: { name: 'splash' },
    // component: () => import(/* webpackChunkName: "logIn-organization" */ '../views/Login/OrganizationLoginPage.vue'),
    // meta: {
    //   requireAuth: false
    // }
  },
  {
    path: '/:locale',
    beforeEnter: (to, from, next) => {
      const locale = to.params.locale
      localStorage.setItem('locale', locale)
      if (locale !== null) {
        store.dispatch('video/initialize')
      }
      // store.dispatch('walkthrough/setLang', locale)
      // store.dispatch('video/initialize', locale)
      const supportedLocales = process.env.VUE_APP_I18N_FALLBACK_LOCALE.split(',')
      if (locale.length === 0) {
        return next(process.env.VUE_APP_I18N_LOCALE)
      } else if (!supportedLocales.includes(locale)) {
        return next(process.env.VUE_APP_I18N_LOCALE)
      }
      if (i18n.locale !== locale) {
        i18n.locale = locale
      }
      return next()
    },
    component: () => import(/* webpackChunkName: "splash" */ '../views/Home.vue'),
    children: [
      {
        path: '',
        name: 'splash',
        component: () => import(/* webpackChunkName: "splash" */ '../views/SplashScreen.vue'),
        meta: {
          requireAuth: false
        }
      },
      {
        path: 'language',
        name: 'language',
        component: () => import(/* webpackChunkName: "language" */ '../views/Login/LanguageSwitcher.vue'),
        meta: {
          requireAuth: false
        }
      },
      {
        path: 'signup/email',
        name: 'email',
        component: () => import(/* webpackChunkName: "logIn-email" */ '../views/Login/Email.vue'),
        meta: {
          requireAuth: false
        }
      },
      {
        path: 'signup/verify',
        name: 'signup',
        component: () => import(/* webpackChunkName: "logIn-email-verify" */ '../views/Login/EmailVerify.vue'),
        meta: {
          requireAuth: false
        }
      },
      {
        path: 'signup/terms-policy',
        name: 'terms policy',
        component: () => import(/* webpackChunkName: "logIn-terms-policy" */ '../views/Login/TermsPolicy.vue'),
        meta: {
          requireAuth: false
        }
      },
      // {
      //   path: 'signup/terms',
      //   name: 'terms',
      //   component: () => import(/* webpackChunkName: "logIn-terms" */ '../views/Login/Terms.vue'),
      //   meta: {
      //     requireAuth: false
      //   }
      // },
      // {
      //   path: 'signup/policy',
      //   name: 'policy',
      //   component: () => import(/* webpackChunkName: "logIn-policy" */ '../views/Login/Policy.vue'),
      //   meta: {
      //     requireAuth: false
      //   }
      // },
      {
        path: 'signup/success',
        name: 'success',
        component: () => import(/* webpackChunkName: "logIn-success" */ '../views/Login/Success.vue'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: 'login/email',
        name: 'log in',
        component: () => import(/* webpackChunkName: "logIn-email" */ '../views/Login/LoginEmail.vue'),
        meta: {
          requireAuth: false
        }
      },
      {
        path: 'login/verify',
        name: 'email verify',
        component: () => import(/* webpackChunkName: "logIn-email-verify" */ '../views/Login/LoginEmailVerify.vue'),
        meta: {
          requireAuth: false
        }
      },
      {
        path: 'prepaid-code',
        name: 'prepaid code',
        component: () => import(/* webpackChunkName: "location" */ '../views/Login/PrePaidCode.vue'),
        meta: {
          requireAuth: false
        }
      },
      {
        path: 'location',
        name: 'location',
        component: () => import(/* webpackChunkName: "location" */ '../views/Login/Location.vue')
      },
      {
        path: 'demographic-welcome',
        name: 'demographic welcome',
        component: () => import(/* webpackChunkName: "Demographic_welcome" */ '../views/Demographic/Welcome.vue'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: 'demographic',
        name: 'demographic',
        component: () => import(/* webpackChunkName: "demographic" */ '../views/Demographic/Demographic.vue'),
        children: [
          {
            path: '1',
            name: 'demographic 1',
            component: () => import(/* webpackChunkName: "Step_1" */ '../views/Demographic/Step1.vue'),
            meta: {
              advancement: 0,
              requireAuth: true
            }
          },
          {
            path: '2',
            name: 'demographic 2',
            component: () => import(/* webpackChunkName: "Step_2" */ '../views/Demographic/Step2.vue'),
            meta: {
              advancement: 1,
              requireAuth: true
            }
          },
          {
            path: '3',
            name: 'demographic 3',
            component: () => import(/* webpackChunkName: "Step_3" */ '../views/Demographic/Step3.vue'),
            meta: {
              advancement: 2,
              requireAuth: true
            }
          },
          {
            path: '4',
            name: 'demographic 4',
            component: () => import(/* webpackChunkName: "Step_4" */ '../views/Demographic/Step4.vue'),
            meta: {
              advancement: 3,
              requireAuth: true
            }
          }
        ]
      },
      {
        path: 'pre-test/video',
        name: 'pre test video',
        component: () => import(/* webpackChunkName: "Pre-test question video" */ '../views/PreTest/Video.vue'),
        meta: {
          advancement: 0,
          hideStepCount: true,
          requireAuth: true
        }
      },
      {
        path: 'pre-test',
        name: 'pre test',
        component: () => import(/* webpackChunkName: "preTest" */ '../views/PreTest/PreTest.vue'),
        children: [
          {
            path: '1',
            name: 'pre test 1',
            component: () => import(/* webpackChunkName: "Step_1" */ '../views/PreTest/Step1.vue'),
            meta: {
              advancement: 1,
              requireAuth: true
            }
          },
          {
            path: '2',
            name: 'pre test 2',
            component: () => import(/* webpackChunkName: "Step_2" */ '../views/PreTest/Step2.vue'),
            meta: {
              advancement: 2,
              requireAuth: true
            }
          },
          {
            path: '3',
            name: 'pre test 3',
            component: () => import(/* webpackChunkName: "Step_3" */ '../views/PreTest/Step3.vue'),
            meta: {
              advancement: 3,
              requireAuth: true
            }
          },
          {
            path: '4',
            name: 'pre test 4',
            component: () => import(/* webpackChunkName: "Step_4" */ '../views/PreTest/Step4.vue'),
            meta: {
              advancement: 4,
              requireAuth: true
            }
          }
        ]
      },
      {
        path: 'quidel',
        name: 'quidel',
        component: () => import(/* webpackChunkName: "quidle" */ '../views/Quidel/Quidel.vue'),
        children: [
          {
            path: '1',
            name: 'explanation 1',
            component: () => import(/* webpackChunkName: "Step_1" */ '../views/Quidel/Step1.vue'),
            meta: {
              requireAuth: true
            }
          },
          {
            path: '2/:name?',
            name: 'explanation 2',
            component: () => import(/* webpackChunkName: "Step_2" */ '../views/Quidel/step_2/Step2.vue'),
            meta: {
              requireAuth: true
            }
          },
          {
            path: '3',
            name: 'explanation 3',
            component: () => import(/* webpackChunkName: "Step_3" */ '../views/Quidel/Step3.vue'),
            meta: {
              requireAuth: true
            }
          },
          {
            path: '4/:name?',
            name: 'explanation 4',
            component: () => import(/* webpackChunkName: "Step_4" */ '../views/Quidel/step_4/Step4.vue'),
            meta: {
              requireAuth: true
            }
          },
          {
            path: '5',
            name: 'explanation 5',
            component: () => import(/* webpackChunkName: "Step_4" */ '../views/Quidel/Step5.vue'),
            meta: {
              requireAuth: true
            }
          },
          {
            path: 'test-in-progress',
            name: 'test in progress',
            component: () => import(/* webpackChunkName: "TestInProgress" */ '../views/Quidel/TestInProgress.vue'),
            meta: {
              requireAuth: true
            }
          },
          {
            path: 'result',
            name: 'result',
            component: () => import(/* webpackChunkName: "Result" */ '../views/Quidel/Result.vue'),
            meta: {
              requireAuth: true
            }
          }
        ]
      },
      {
        path: 'outcome/:type',
        name: 'outcome',
        component: () => import(/* webpackChunkName: "Outcome" */ '../views/Outcome/Outcome.vue'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: 'previous-result/:type',
        name: 'previous result',
        component: () => import(/* webpackChunkName: "Outcome" */ '../views/PreviousResult/Outcome.vue'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: 'faq',
        name: 'faq',
        component: () => import(/* webpackChunkName: "faq" */ '../views/Faq.vue')
      },
      {
        path: 'mission/quidel',
        name: 'mission quidel',
        component: () => import(/* webpackChunkName: "quidel" */ '../views/Mission/Quidel.vue')
      },
      {
        path: 'mission/bella',
        name: 'mission bella',
        component: () => import(/* webpackChunkName: "bella" */ '../views/Mission/Bella.vue')
      },
      {
        path: 'terms',
        name: 'terms',
        component: () => import(/* webpackChunkName: "terms" */ '../views/Terms.vue')
      },
      {
        path: 'policy',
        name: 'policy',
        component: () => import(/* webpackChunkName: "policy" */ '../views/Policy.vue')
      },
      {
        path: 'rating',
        name: 'rating',
        component: () => import(/* webpackChunkName: "rating" */ '../views/Rating/Rating.vue'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: 'rating/success',
        name: 'rating-success',
        component: () => import(/* webpackChunkName: "FeedBack" */ '../views/Rating/Success.vue'),
        meta: {
          requireAuth: true
        }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const isAuth = localStorage.getItem('isAuth')
  // const isAuth = store.getters['auth/isAuth']
  if (to?.meta?.requireAuth && isAuth === 'false') {
    next({ name: 'splash' })
  } else if (to?.meta?.requireAuth === false) {
    localStorage.setItem('isAuth', 'false')
    next()
  } else next()
})

export default router
