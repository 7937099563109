import { ActionTree } from 'vuex'
import { ApplicationState } from '@/store'
import { TermState } from '@/store/modules/terms/index'
import termsDataSource from '@/services/terms-data-source'

export default {
  getTermsData () {
    return termsDataSource
      .getTermsData()
      .then((data:any) => {
        return data
      })
  }
} as ActionTree<TermState, ApplicationState>
