import axios from 'axios'
import authenticationService from '@/services/authentication-service'

export interface SentEmailOtpService {
  send(email: string): Promise<boolean>
}

export class FirebaseSentEmailOtpService implements SentEmailOtpService {
  async send (email: string): Promise<boolean> {
    if (!process.env.VUE_APP_FIREBASE_FUNCTIONS_BASE_URL) {
      throw new Error('No base url specified for firebase functions')
    }

    try {
      const token = await authenticationService.getToken()
      const locale = localStorage.getItem('locale') as string
      const payload = buildPayload(email)
      const response = await axios
        .post<unknown>(`${process.env.VUE_APP_FIREBASE_FUNCTIONS_BASE_URL}/api/users/auth/get_code`, payload, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-type': 'application/json',
            'Accept-Language': locale
          }
        })
      return true
    } catch (error) {
      console.error('[FirebaseSentEmailOtpService] send failed', error)
      return false
    }
  }
}

function buildPayload (email: string) {
  return { email, source: 'qvue-travel' }
}
export const instance = new FirebaseSentEmailOtpService()

export default instance
