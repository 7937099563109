










import { Component, Vue } from 'vue-property-decorator'
@Component({})
export default class ChatButtonProxy extends Vue {
  $refs!: {
    fakeBtn: HTMLElement
  }

  private btnPos = {
    top: '0px'
  }

  private messageUsBtnVisibility = true
  private chatInterval:any

  private async created (): Promise<void> {
    if (!this.isMobile()) {
      this.chatInterval = setInterval(() => {
        this.messageUsVisibility()
      }, 1000)
    }
  }

  private mounted (): void {
    this.position()
    this.$root.$on('open-chat', () => {
      this.triggerEvent()
    })
  }

  public position (): void {
    const alignmentPoint = document.querySelector('.alignment-point')
    setTimeout(() => {
      if (alignmentPoint) {
        this.btnPos.top = alignmentPoint.getBoundingClientRect().top + 'px'
        if (window.innerWidth > 767) {
          this.$root.$on('timer-show', () => {
            this.btnPos.top = alignmentPoint.getBoundingClientRect().top + 46 + 'px'
          })
          this.$root.$on('timer-hide', () => {
            this.btnPos.top = alignmentPoint.getBoundingClientRect().top + 'px'
          })
        }
      }
    }, 100)
  }

  public triggerEvent (): void {
    const a = setInterval(() => {
      const buttons = document.getElementsByClassName('LPMcontainer LPMoverlay')
      const button: HTMLButtonElement | any = buttons.length > 0 ? buttons[0] : null
      if (button) {
        button.click()
        clearInterval(a)
      }
    }, 10)
  }

  private messageUsVisibility () {
    const routePath = this.$route.path
    if (routePath?.includes('pre-test') || routePath?.includes('faq')) {
      this.isChatMinimize()
    }
  }

  private async isChatMinimize () {
    var styleArray = await document.querySelector('div.lp_minimized')
      ?.attributes.getNamedItem('style')?.value.split(';')
    // console.log('display: block ? ', styleArray?.includes(' display: block'))
    // console.log('display: block ?? ', styleArray?.includes(' display: block') ?? false)
    this.messageUsBtnVisibility = styleArray?.includes(' display: block') as boolean ?? false
  }

  private isMobile ():boolean {
    if (window.innerWidth < 768) {
      return true
    }
    return false
  }

  private beforeDestroy () {
    clearInterval(this.chatInterval)
  }
}
