import firebaseService from '@/services/firebase-service'
export interface UserDataStore {
    writeUserData(userId: string | null, data: any): Promise<void>;
    writeResultData(userId: string | null, data: string): Promise<void>;
    getUserData(userId: string | null): Promise<void>;
    getResultData(userId: string): Promise<void>;
    deleteResultData(userId: string): Promise<void>;
}

export class FirebaseUserDataStore implements UserDataStore {
  public isFirebase = false
  async writeUserData (userId: string, data: any): Promise<void> {
    if (this.isFirebase) {
      return await firebaseService.databaseConnection().then((db) => {
        db.ref('users/' + userId).update({ ...data })
      })
    } else {
      const oldUser = JSON.parse(localStorage.getItem(userId) || '{}')
      const allObjcet = { ...oldUser, ...data }
      // const newobject = this.UniqueData(allObjcet)

      // console.log('newobject', newobject)

      return localStorage.setItem(userId, JSON.stringify({ ...allObjcet }))
    }
  }

  async writeResultData (userId: string, data: string): Promise<void> {
    return await firebaseService.databaseConnection().then((db) => {
      db.ref('qvue_travel_temp/' + userId).set(data)
    })
  }

  async deleteResultData (userId: string): Promise<void> {
    return await firebaseService.databaseConnection().then((db) => {
      db.ref('qvue_travel_temp/' + userId).off('value')
      return db.ref('qvue_travel_temp/' + userId).remove()
    })
  }

  // UniqueData (arr: Array<string>): Array<void> {
  //   const objArray = {}
  //   var uniques: any = []
  //   var myArray :any = {}
  //   var itemsFound :any = {}
  //   Object.keys(arr).map((data:any, index) => {
  //     console.log('data', data)
  //     console.log('index', arr[data])
  //     var stringified = data
  //     if (itemsFound[stringified]) {
  //       arr[data] = arr[data]
  //     }else{
  //       uniques.push(arr[data])
  //       itemsFound[stringified] = true
  //       myArray[stringified] = index;
  //     }
  //   })
  // }

  async getUserData (userId: string): Promise<void> {
    if (this.isFirebase) {
      return await firebaseService.databaseConnection().then((db) => {
        return db.ref().child('users').child(userId).get().then((snapshot:any) => {
          return snapshot.val()
        })
      })
    } else {
      return JSON.parse(localStorage.getItem(userId) || '{}')
    }
  }

  async getResultData (userId: string): Promise<void> {
    return await firebaseService.databaseConnection().then((db) => {
      return db.ref('qvue_travel_temp/' + userId).on('value', (snapshot:any) => {
        const data = snapshot.val()
        console.log('data', data)
        return data
      })
    })
  }
}

export const instance = new FirebaseUserDataStore()

export default instance
