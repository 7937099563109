import { ATestOutcomeHighlight } from '@/store/modules/walkthrough/types/outcomes/resultOverview/highlights/ATestOutcomeHighlight'
import assetLoader from '@/utils/asset-loader'
import { HighlightStatus } from '@/store/modules/walkthrough/types/pre-test/highlightStatus'
import { Symptoms } from '@/store/modules/walkthrough/types/pre-test/symptoms'

export class SymptomsHighlight extends ATestOutcomeHighlight {
  static readonly NO_SYMPTOMS = new SymptomsHighlight(assetLoader.load('result/thumbs-up-icon.svg'), 'quidel.result.highlights.symptoms.not_have_symptoms.title', 'quidel.result.highlights.symptoms.not_have_symptoms.description', HighlightStatus.ok)
  static readonly HAVE_SYMPTOMS = new SymptomsHighlight(assetLoader.load('result/exclamation-mark-icon.svg'), 'quidel.result.highlights.symptoms.have_symptoms.title', 'quidel.result.highlights.symptoms.have_symptoms.description', HighlightStatus.warning)

  // eslint-disable-next-line no-useless-constructor
  private constructor (iconSrc: string, titleTextKey: string, descriptionTextKey: string, status: HighlightStatus) {
    super(iconSrc, titleTextKey, descriptionTextKey, status)
  }

  public static for (symptoms: Symptoms): SymptomsHighlight {
    return symptoms.any()
      ? SymptomsHighlight.HAVE_SYMPTOMS
      : SymptomsHighlight.NO_SYMPTOMS
  }
}
