















import { Component, Vue } from 'vue-property-decorator'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import LineTimer from '@/components/common/LineTimer.vue'
import Chat from '@/components/common/Chat.vue'
import IOSScroll from '@/helper/iOS-scroll'
import PreLoader from '@/components/PreLoader.vue'
interface ITimer {
  visibility: boolean
  timeName: string
}

@Component({
  components: {
    Chat,
    Header,
    Footer,
    LineTimer,
    PreLoader
  }
})
export default class App extends Vue {
  private isTimer = false
  private timeName = ''

  private created (): void {
    this.$root.$on('line-timer', (e: ITimer) => {
      this.isTimer = e.visibility
      this.timeName = e.timeName
    })
    const isAuth = localStorage.getItem('isAuth')
    if (isAuth === 'true') {
      const data = this.$store.getters['auth/getUserSessionData']
      if (data !== null && 'is_demographic_filled' in data && 'fullName' in data && 'passportNumber' in data) {
        const userInfo = {
          fullName: data.fullName,
          passportNumber: data.passportNumber,
          flyingDate: data.flyingDate
        }
        this.$store.dispatch('walkthrough/setUserInfo', userInfo)
        this.$store.dispatch('auth/userDataStore', 'organizationCode')
        this.$store.dispatch('auth/userDataStore', 'setEmail')
        this.$store.dispatch('auth/userDataStore', 'setAuth')
      } else {
        this.$store.dispatch('auth/userDataStore', 'organizationCode')
        this.$store.dispatch('auth/userDataStore', 'setEmail')
        this.$store.dispatch('auth/userDataStore', 'setAuth')
        this.$store.dispatch('auth/setDemographic', false)
        this.$router.push('demographic-welcome')
      }
    }
    // Listen for messages
    if (sessionStorage.getItem('timer/quidel/4') === 'isRun') {
      this.isTimer = true
      this.timeName = 'time/quidel/4'
    } else if (sessionStorage.getItem('timer/quidel/2') === 'isRun') {
      this.isTimer = true
      this.timeName = 'time/quidel/2'
    }
  }

  private mounted (): void {
    IOSScroll()
  }
}
