import axios from 'axios'
import { UserInfo } from '@/store/modules/walkthrough/types/demographic/userInfo'
import authenticationService from '@/services/authentication-service'
import moment from 'moment'

export interface StatisticalDataService {
  send(userId: string, organizationCode: string, dateCompleted: Date, validUntil: Date, userInfo: UserInfo, result: string, email: string): Promise<void>
}

export class FirebaseStatisticalDataService implements StatisticalDataService {
  async send (userId: string, organizationCode: string, dateCompleted: Date, validUntil: Date, userInfo: UserInfo, result: string, email: string): Promise<void> {
    if (!process.env.VUE_APP_FIREBASE_FUNCTIONS_BASE_URL) {
      throw new Error('No base url specified for firebase functions')
    }

    try {
      const token = await authenticationService.getToken()
      const locale = localStorage.getItem('locale') as string
      const payload = buildPayload(dateCompleted, validUntil, userInfo, result, email)
      const response = await axios
        .post<unknown>(`${process.env.VUE_APP_FIREBASE_FUNCTIONS_BASE_URL}/api/organizations/${organizationCode}/submit`, payload, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-type': 'application/json',
            'Accept-Language': locale
          }
        })
    } catch (error) {
      console.error('[FirebaseStatisticalDataService] send failed', error)
    }
  }
}

function buildPayload (dateCompleted: Date, validUntil: Date, userInfo: UserInfo, result: string, email: string) {
  return {
    demographics: {
      email: email,
      full_name: userInfo.fullName,
      date_of_flight: userInfo.flyingDate,
      passport_number: userInfo.passportNumber
    },
    test: {
      test_result: parseInt(result.slice(-1)),
      completed_at: dateCompleted,
      valid_until: validUntil,
      current_date_localized: moment(dateCompleted).format('MM/DD/YYYY h:mm a'),
      valid_until_localized: moment(validUntil).format('MM/DD/YYYY h:mm a')
    }
  }
}

// function mapToResultTypeStringValue (result: string): string {
//   if (result === 'test_result=2' || result === 'test_result=1') {
//     return 'POSITIVE'
//   } else if (result === 'test_result=3') {
//     return 'NEGATIVE'
//   } else {
//     return 'INCONCLUSIVE'
//   }
// }

export const instance = new FirebaseStatisticalDataService()

export default instance
