import { ActionTree } from 'vuex'
import { ApplicationState } from '@/store'
import { FAQState } from '@/store/modules/faq/index'
import faqDataSource from '@/services/faq-data-source'

export default {
  getFaqData () {
    return faqDataSource
      .getFaqData()
      .then((data:any) => {
        return data
      })
  }
} as ActionTree<FAQState, ApplicationState>
