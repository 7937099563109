/* eslint-disable camelcase */
import { Module } from 'vuex'
import { ApplicationState } from '@/store'
import { TestResultOption } from '@/store/modules/walkthrough/types/types'
import getters from '@/store/modules/walkthrough/getters'
import actions from '@/store/modules/walkthrough/actions'
import mutations from '@/store/modules/walkthrough/mutations'
import { DeviceCompatible } from './types/pre-test/deviceCompatible'
import { Passport } from '@/store/modules/walkthrough/types/pre-test/passport'
import { PrePared } from '@/store/modules/walkthrough/types/pre-test/prePared'
import { HandsClean } from '@/store/modules/walkthrough/types/pre-test/handsClean'
import { VaccinationState } from '@/store/modules/walkthrough/types/pre-test/vaccinationState'
import { Symptoms } from '@/store/modules/walkthrough/types/pre-test/symptoms'
import { Exposure } from '@/store/modules/walkthrough/types/pre-test/exposure'
import { ConfirmIdentity } from '@/store/modules/walkthrough/types/demographic/confirmIdentity'
import { UserInfo } from '@/store/modules/walkthrough/types/demographic/userInfo'

export interface WalkthroughState {
  have_free_time: boolean
  device_compatible: DeviceCompatible | null
  passport_state: Passport | null
  prepared_state: PrePared | null
  handsclean_state: HandsClean | null
  vaccination_state: VaccinationState | null;
  symptoms: Symptoms | null;
  user_info: UserInfo | null;
  confirm_identity: ConfirmIdentity | null;
  exposure: Exposure | null;
  selected_option: TestResultOption | null;
  test_completion_time: Date | null;
  test_validity_expiration_time: Date | null;
  test_validity_duration_in_hours: number;
  test_result: string | null;
  result_submitted: boolean;
}

export const initialState = {
  have_free_time: false,
  device_compatible: null,
  passport_state: null,
  prepared_state: null,
  handsclean_state: null,
  vaccination_state: null,
  symptoms: null,
  user_info: {
    fullName: '',
    passportNumber: '',
    flyingDate: ''
  },
  confirm_identity: null,
  exposure: null,
  selected_option: null,
  test_completion_time: null,
  test_validity_expiration_time: null,
  test_result: '',
  result_submitted: false,
  test_validity_duration_in_hours: 72
} as WalkthroughState

export function getInitialState (): WalkthroughState {
  return initialState
}

export default {
  namespaced: true,
  state: getInitialState,
  getters,
  actions,
  mutations
} as Module<WalkthroughState, ApplicationState>
