import { Module } from 'vuex'
import { ApplicationState } from '@/store'
import actions from './actions'

export interface PolicyState {
  policyData: any
}

export const initialState: PolicyState = {
  policyData: null
}

export function getInitialState (): PolicyState {
  return initialState
}

export default {
  namespaced: true,
  state: getInitialState,
  actions
} as Module<PolicyState, ApplicationState>

export class PolicyState {
}

export class policyState {
}
