import { AuthState } from '@/store/modules/auth/index'
import { MutationTree } from 'vuex'
import { AuthorizationOutcome } from '@/services/organization-authorization-service'

export default {
  userId: (state, userId: string) => { state.userId = userId },
  setUserData: (state, data: any) => { state.userSessionData = { ...data } },
  userAuthToken: (state, userAuthToken: string) => { state.userAuthToken = userAuthToken },
  organizationCode: (state, organizationCode: string) => { state.organizationCode = organizationCode },
  setEmail: (state, email: string) => { state.email = email },
  setAuth: (state, auth: boolean) => { state.isAuth = auth },
  setEmailOtpVerified: (state, auth: boolean) => { state.otpVerified = auth },
  setEmailOtp: (state, emailOtp: string) => { state.emailOtp = emailOtp },
  authorizationIsOngoing: (state, authorizationIsOngoing: boolean) => { state.authorizationIsOngoing = authorizationIsOngoing },
  authorizationOutcome: (state, authorizationOutcome: AuthorizationOutcome) => { state.authorizationOutcome = authorizationOutcome },
  authorizationError: (state, authorizationError: Error) => { state.authorizationError = authorizationError }
} as MutationTree<AuthState>
