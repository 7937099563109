import { ActionTree } from 'vuex'
import { ApplicationState } from '@/store/index'
import firebaseService from '@/services/firebase-service'
import statisticalDataCollectionService from '@/services/statistical-data-service'

export default {

  initialize (context): Promise<void> {
    const firebaseServiceInitializationPromise = firebaseService.init()
    const videoModuleInitializationPromise = context.dispatch('video/initialize')
    const authModuleInitializationPromise = context.dispatch('auth/init')
    // const fetchGeolocation = context.dispatch('auth/fetchGeolocation')

    return Promise
      .all([
        firebaseServiceInitializationPromise,
        videoModuleInitializationPromise,
        authModuleInitializationPromise
      ])
      .then(() => context.commit('initialized', true))
  }

} as ActionTree<ApplicationState, unknown>
