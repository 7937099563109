import firebaseService from './firebase-service'

export declare type Terms = any

export interface ITermsDataSource {
  getTermsData(): Promise<Terms>
}

export class RemoteConfigTermsDataSource implements ITermsDataSource {
  getTermsData (): Promise<Terms> {
    return firebaseService.getRemoteConfig().then((remoteConfig) => {
      return remoteConfig.fetchAndActivate().then(() => {
        let collectionName
        let configurationValue: string
        const locale = localStorage.getItem('locale') as string
        if (locale === 'es') { collectionName = 'bh_qvue_travel_terms_of_use_es' } else { collectionName = 'bh_terms_of_service' }
        // eslint-disable-next-line prefer-const
        configurationValue = remoteConfig.getString(collectionName)
        try {
          return configurationValue
        } catch (e) {
          console.log(e)
        }
      })
    })
  }
}

export const instance = new RemoteConfigTermsDataSource()
export default instance
