import firebaseService from './firebase-service'

export declare type Faq = any

export interface IFaqDataSource {
  getFaqData(): Promise<Faq>
}

export class RemoteConfigFaqDataSource implements IFaqDataSource {
  getFaqData (): Promise<Faq> {
    return firebaseService.getRemoteConfig().then((remoteConfig) => {
      return remoteConfig.fetchAndActivate().then(() => {
        let configurationValue
        const locale = localStorage.getItem('locale') as string
        if (locale === 'es') { configurationValue = remoteConfig.getString('bh_qvue_travel_faq_es') } else { configurationValue = remoteConfig.getString('bh_qvue_travel_faq_en') }
        configurationValue = typeof configurationValue !== 'string'
          ? JSON.stringify(configurationValue)
          : configurationValue
        try {
          return JSON.parse(configurationValue)
        } catch (e) {
          console.log(e)
        }
      })
    })
  }
}

export const instance = new RemoteConfigFaqDataSource()
export default instance
