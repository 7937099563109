

















import { Component, Vue } from 'vue-property-decorator'

@Component({})
export default class PreLoader extends Vue {
  private isLoader = false
  private created (): void {
    this.$root.$on('show-loader', () => {
      this.isLoader = true
    })
    this.$root.$on('close-loader', () => {
      this.isLoader = false
    })
  }
}
