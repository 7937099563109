import { TestOutcomeHighlight } from '@/store/modules/walkthrough/types/outcomes/resultOverview/highlights/testOutcomeHighlight'

export class ResultOverview {
  // eslint-disable-next-line no-useless-constructor
  public constructor (
    public readonly subTitleTextKey: string,
    public readonly descriptionTextKey: string,
    public readonly highlights: TestOutcomeHighlight[]
  ) {}
}
