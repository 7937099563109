import axios from 'axios'

export enum AuthorizationOutcome {
  unknown,
  error,
  valid,
  invalid
}

// INTERFACE

export interface OrganizationAuthorizationService {
  authorize(organizationCode: string): Promise<AuthorizationOutcome>
}

// IMPLEMENTATION

export class FirebaseOrganizationAuthorizationService implements OrganizationAuthorizationService {
  async authorize (organizationCode: string): Promise<AuthorizationOutcome> {
    if (!process.env.VUE_APP_FIREBASE_FUNCTIONS_BASE_URL) {
      throw new Error('No base url specified for firebase functions')
    }

    let result = AuthorizationOutcome.unknown

    try {
      const response = await axios
        .get<{ code: string }>(`${process.env.VUE_APP_FIREBASE_FUNCTIONS_BASE_URL}/api/organizations/${organizationCode}/validate?bid=${process.env.VUE_APP_FIREBASE_BID}`, {
          headers: {
            'Content-Type': 'application/json'
          }
        })

      result = !response.data.code
        ? AuthorizationOutcome.invalid
        : AuthorizationOutcome.valid
    } catch (error:any) {
      if (error.code > 500) {
        throw new Error(`Remote server error ${error.code}`)
      } else {
        result = AuthorizationOutcome.invalid
      }
    }

    return result
  }
}

export const instance = new FirebaseOrganizationAuthorizationService()

export default instance
