import { ModuleTree } from 'vuex'
import { ApplicationState } from '@/store'
import auth from './auth'
import video from './video/index'
import faq from './faq/index'
import terms from './terms/index'
import policy from './policy/index'
import walkthrough from './walkthrough'

export default {
  auth,
  video,
  faq,
  policy,
  terms,
  walkthrough
} as ModuleTree<ApplicationState>
