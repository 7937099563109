



















































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { Route } from 'vue-router'
import { LocaleMessages } from 'vue-i18n'
interface ILinks {
  icon: string
  path: string
  pathName: string
  text: string | LocaleMessages
  subLinks: [] | null | any
  isSubLinks: boolean,
  style: boolean
}
@Component({
  data () {
    return {
      navLinks: [
        {
          icon: require('../assets/images/mobile-nav/icon-20-back-home.svg'),
          path: '/',
          pathName: 'faq',
          text: this.$t('mobileNav[0][0].label')
        },
        {
          icon: require('../assets/images/mobile-nav/icon-20-about.svg'),
          text: this.$t('mobileNav[0][2].label'),
          subLinks: this.$t('mobileNav[0][2].links'),
          isSubLinks: false
        },
        {
          icon: require('../assets/images/mobile-nav/icon-20-faq-white.svg'),
          path: '/faq',
          pathName: 'faq',
          text: this.$t('mobileNav[0][3].label')
        }
        // {
        //   icon: require('../assets/images/mobile-nav/icon-20-faq-white.svg'),
        //   path: '/previous-result',
        //   pathName: 'previous result',
        //   text: this.$t('mobileNav[0][3].label')
        // }
      ],
      saveLink: [
        {
          icon: require('../assets/images/mobile-nav/icon-20-back-home.svg'),
          path: '/',
          text: this.$t('mobileNav[0][0].label')
        },
        {
          icon: require('../assets/images/mobile-nav/icon-20-about.svg'),
          text: this.$t('mobileNav[0][2].label'),
          subLinks: this.$t('mobileNav[0][2].links'),
          isSubLinks: false
        },
        {
          icon: require('../assets/images/mobile-nav/icon-20-faq-white.svg'),
          path: '/faq',
          pathName: 'faq',
          text: this.$t('mobileNav[0][3].label')
        }
      ]
    }
  }
})
export default class Header extends Vue {
  @Prop({ type: Boolean, required: false, default: false })
  private previousResult!:boolean

  private previousResultData = ''
  navLinks: Array<ILinks> = []
  saveLink: Array<ILinks> = []
  // private mounted (): void {
  //   const result = localStorage.getItem('testResult') as string
  //   if (result !== '') {
  //     this.previousResultData = result
  //   }
  // }

  private goTo (item: ILinks): void {
    this.$emit('close-nav')
    if (item.path === '/' && this.isAuth) {
      if (this.$route.path.includes('demographic')) {
        this.$router.push({ name: 'demographic welcome' })
      } else this.$router.push({ name: 'pre test video' })
    } else this.$router.push({ name: item.pathName })
  }

  private gotoPreviousResult (): void {
    this.$emit('close-nav')
    const result = localStorage.getItem('testResult') as string
    const locale = localStorage.getItem('locale') as string
    if (result !== undefined || result !== '' || result !== null) {
      var data = atob(result)
      if (data === 'test_result=2' || data === 'test_result=1') {
        this.$router.push('/' + locale + '/previous-result/2')
      } else if (data === 'test_result=3') {
        this.$router.push('/' + locale + '/previous-result/1')
      } else if (data === 'test_result=4' || data === 'test_result=5') {
        this.$router.push('/' + locale + '/previous-result/3')
      }
    }
  }

  private toggleSubLink (index: number): void {
    if (this.navLinks?.length) this.navLinks[index].isSubLinks = !this.navLinks[index].isSubLinks
  }

  private navLink (): void {
    const title: HTMLElement | null = document.querySelector('.d-flex.align-items-center h1')
    if (title) {
      if (this.navLinks?.length) {
        const obg: ILinks = {
          icon: '',
          path: '',
          pathName: '',
          text: title.innerText,
          subLinks: null,
          isSubLinks: false,
          style: true
        }
        const restart = {
          icon: require('../assets/images/mobile-nav/icon-20-restart.svg'),
          path: '',
          pathName: '',
          text: this.$t('mobileNav[1][0].label'),
          subLinks: null,
          isSubLinks: false,
          style: true
        }
        const aboutUs = {
          icon: require('../assets/images/mobile-nav/icon-20-about.svg'),
          text: this.$t('mobileNav[0][2].label'),
          subLinks: this.$t('mobileNav[0][2].links'),
          isSubLinks: false,
          path: '',
          pathName: '',
          style: true
        }
        this.navLinks[1] = restart
        const a = this.navLinks
        a.splice(1, 0, obg)
        a.splice(3, 0, aboutUs)
        const uniqueAddresses:any = Array.from(new Set(a.map(a => a.text)))
          .map(text => {
            return a.find(a => a.text === text)
          })
        this.navLinks = uniqueAddresses
      }
    }
  }

  private defaultNav (): void {
    const el: ILinks = {
      icon: require('../assets/images/mobile-nav/icon-20-test.svg'),
      path: '/pre-test/1',
      pathName: 'pre test 1',
      text: this.$t('mobileNav[0][1].label'),
      subLinks: null,
      isSubLinks: false,
      style: false
    }
    // if (this.navLinks?.length > 3) {
    //   if (this.$route.path.includes('pre-test/video')) {
    //     this.navLinks.splice(1, 0, el)
    //   } else this.navLinks[1] = el
    //   // this.navLinks.splice(1, 1)
    // }

    if (this.$route.path.includes('pre-test/video')) {
      if (this.navLinks?.length > 3) {
        this.navLinks.splice(2, 1)
        this.navLinks.splice(1, 1)
      }
      this.navLinks.splice(1, 0, el)
    } else if (this.navLinks?.length > 3) {
      this.navLinks.splice(2, 1)
      this.navLinks.splice(1, 1)
    }
  }

  @Watch('$route')
  onRouteChange (route: Route):void | boolean {
    if ((route.path.includes('pre-test') && !route.path.includes('pre-test/video')) || route.path.includes('quidel/')) this.navLink()
    else this.defaultNav()
  }

  get isDemographyFilled ():boolean {
    const data = this.$store.getters['auth/getUserSessionData']
    if (Object.prototype.hasOwnProperty.call(data, 'is_demographic_filled')) {
      if (data.is_demographic_filled) {
        return true
      }
    }
    return false
  }

  get isAuth (): boolean {
    return this.$store.getters['auth/isAuth']
  }
}
