import { Module } from 'vuex'
import { ApplicationState } from '@/store'
import actions from './actions'

export interface FAQState {
  faqData: any
}

export const initialState: FAQState = {
  faqData: null
}

export function getInitialState (): FAQState {
  return initialState
}

export default {
  namespaced: true,
  state: getInitialState,
  actions
} as Module<FAQState, ApplicationState>
