import { ATestOutcomeHighlight } from '@/store/modules/walkthrough/types/outcomes/resultOverview/highlights/ATestOutcomeHighlight'
import assetLoader from '@/utils/asset-loader'
import { HighlightStatus } from '@/store/modules/walkthrough/types/pre-test/highlightStatus'
import { TestResult } from '@/store/modules/walkthrough/types/testResult'

export class TestResultHighlight extends ATestOutcomeHighlight {
  static readonly POSITIVE = new TestResultHighlight(assetLoader.load('result/exclamation-mark-icon.svg'), 'quidel.result.highlights.testResult.positive.title.text', 'quidel.result.highlights.testResult.positive.description.text', HighlightStatus.warning)
  static readonly NEGATIVE = new TestResultHighlight(assetLoader.load('result/thumbs-up-icon.svg'), 'quidel.result.highlights.testResult.negative.title.text', 'quidel.result.highlights.testResult.negative.description.text', HighlightStatus.ok)
  static readonly INCONCLUSIVE = new TestResultHighlight(assetLoader.load('result/question-mark-icon.svg'), 'quidel.result.highlights.testResult.inconclusive.title.text', 'quidel.result.highlights.testResult.inconclusive.description.text', HighlightStatus.ok)

  // eslint-disable-next-line no-useless-constructor
  private constructor (iconSrc: string, titleTextKey: string, descriptionTextKey: string, status: HighlightStatus) {
    super(iconSrc, titleTextKey, descriptionTextKey, status)
  }

  public static for (testResult: TestResult): TestResultHighlight {
    if (testResult === TestResult.Positive) {
      return TestResultHighlight.POSITIVE
    } else if (testResult === TestResult.Negative) {
      return TestResultHighlight.NEGATIVE
    } else {
      return TestResultHighlight.INCONCLUSIVE
    }
  }
}
