import Vue from 'vue'
import App from './App.vue'
import './bootstrap-vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import './v-mask'
import './mobile-detection'
import './assets/scss/app.scss'
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
import VueVideoPlayer from 'vue-video-player'
import ChatButtonProxy from './components/ChatButtonProxy.vue'
import 'video.js/dist/video-js.css'
import firebaseService from '@/services/firebase-service'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const moment = require('moment')
Vue.prototype.$screen = new Vue({
  data: {
    screen: {
      width: window.innerWidth,
      height: window.innerHeight - 1,
      pageHeight: window.innerHeight
    }
  }
}).screen

window.addEventListener('load', () => {
  setTimeout(function () {
    Vue.prototype.$screen.width = window.innerWidth
    Vue.prototype.$screen.height = window.innerHeight - 1
    const boxHeader = document.querySelector('.header-wrapper')
    const heightH = (boxHeader as HTMLElement)?.offsetHeight
    const boxFooter = document.querySelector('.footer-wrapper')
    const heightF = (boxFooter as HTMLElement)?.offsetHeight
    Vue.prototype.$screen.pageHeight = Vue.prototype.$screen.height - heightH - heightF
    console.log('load: ' + Vue.prototype.$screen.pageHeight)
    console.log('heightH: ' + heightH + ' heightF: ' + heightF)
  }, 1000)
})

window.addEventListener('resize', () => {
  Vue.prototype.$screen.width = window.innerWidth
  Vue.prototype.$screen.height = window.innerHeight - 1
  const boxHeader = document.querySelector('.header-wrapper')
  const heightH = (boxHeader as HTMLElement)?.offsetHeight
  const boxFooter = document.querySelector('.footer-wrapper')
  const heightF = (boxFooter as HTMLElement)?.offsetHeight
  Vue.prototype.$screen.pageHeight = Vue.prototype.$screen.height - heightH - heightF
})

Vue.use(VueVideoPlayer)
Vue.use(PerfectScrollbar)
Vue.component('ChatButtonProxy', ChatButtonProxy)
Vue.config.productionTip = false
store
  .dispatch('initialize')
  .then(() => {
    new Vue({
      router,
      store,
      i18n,
      render: h => h(App)
    }).$mount('#app')
  })
// setInterval(async () => {
//   await store.dispatch('auth/deleteResultData')
//   await store.dispatch('auth/setResultData')
// }, 1000)
let lpsTag: any
(window as unknown as any).lpTag.hooks.push({
  name: 'AFTER_GET_LINES',
  callback: async function (data: any) {
    const lines = data.data.lines
    console.log('lines', lines)
    if (lines !== undefined && lines.length !== 0) {
      const oldConvId = localStorage.getItem('convId')
      if (oldConvId === '' && lines[0].convId !== undefined) {
        localStorage.setItem('convId', lines[0].convId)
      }
      if (oldConvId !== lines[0].convId && lines[0].convId !== undefined) {
        if (lines[0].history === false && lines[0].state === 'ACCEPT') {
          console.log('QVUE: change convId', lines[0].convId)
          const user = store.getters['walkthrough/userInfo']
          const newConvId = lines[0].convId as string
          localStorage.setItem('convId', newConvId)
          // console.log('user', user)
          if (user.fullName !== '' && user.flyingDate !== '' && user.flyingDate !== '') {
            // console.log('user2', user)
            await store.dispatch('auth/deleteResultData')
            await store.dispatch('auth/setResultData')
            const ctu = localStorage.getItem('ctu') as string
            const lpTag = (window as unknown as any).lpTag
            const userId = store.getters['auth/userId']
            const userEmail = store.getters['auth/getEmail']

            const sdes = [
              {
                type: 'ctmrinfo',
                info: {
                  customerId: userId,
                  userName: user.fullName,
                  registrationDate: {
                    day: moment(user.flyingDate).format('DD'),
                    month: moment(user.flyingDate).format('MM'),
                    year: moment(user.flyingDate).format('YYYY')
                  },
                  socialId: userId,
                  imei: ctu,
                  storeNumber: user.passportNumber
                }
              },
              {
                type: 'personal',
                personal: {
                  contacts: [
                    { email: userEmail }
                  ]
                }
              }
            ]
            console.log('QVUE: SDE info updated', sdes)
            lpTag.sdes.push(sdes)
            console.log(`QVUE: SDE updated successfully convId: ${newConvId}, cuid: ${ctu}`)
            await firebaseService.databaseConnection().then((db) => {
              const ctu = localStorage.getItem('ctu') as string
              return db.ref('qvue_travel_temp/' + ctu).on('value', (snapshot:any) => {
                console.log('ovserver running ctu: ', ctu)
                const data = snapshot.val()
                const locale = localStorage.getItem('locale')
                if (data !== undefined) {
                  if (data === 'test_result=2' || data === 'test_result=1') {
                    store.dispatch('walkthrough/completeTest', data)
                    localStorage.setItem('testResult', btoa(data))
                    router.push('/' + locale + '/outcome/2')
                  } else if (data === 'test_result=3') {
                    store.dispatch('walkthrough/completeTest', data)
                    localStorage.setItem('testResult', btoa(data))
                    router.push('/' + locale + '/outcome/1')
                  } else if (data === 'test_result=4' || data === 'test_result=5') {
                    store.dispatch('walkthrough/completeTest', data)
                    localStorage.setItem('testResult', btoa(data))
                    router.push('/' + locale + '/outcome/3')
                  } else {
                    // this.$store.dispatch('walkthrough/completeTest', data)
                    // this.$router.push('/outcome/3')
                  }
                }
              })
            })
          } else {
            console.log('QVUE: Demographic info not completed', user)
          }
        }
      }
      // if (lines[0].text.elements[0].text.includes('Video')) {
      //   const result = lines[0].metadata[1].reason
      //   if (result) {
      //     let a: any = new URL(result)
      //     a = a.pathname.replace('/', '')
      //     const btnMinimaze: any = document.querySelector('.lp_minimize.lpc_maximized-header__minimize-button.lpc_mobile')
      //     const btn = document.getElementById('join-video-call')
      //     const apply = document.getElementById('apply-btn')
      //     const audio:any = document.getElementById('audio-ring')
      //     if (btn) {
      //       btn.style.display = 'flex'
      //       if (apply) apply.setAttribute('meet-id', a)
      //       if (btnMinimaze) {
      //         btnMinimaze.click()
      //         audio.muted = true
      //         audio.play()
      //       }
      //     }
      //   }
      // } else {
      //   if (lines[0].metadata !== undefined && lines[0].metadata.length === 2) {
      //     const result = lines[0].metadata[1].reason
      //     const btnMinimaze: any = document.querySelector('.lp_minimize.lpc_maximized-header__minimize-button.lpc_mobile')
      //     store
      //       .dispatch('walkthrough/completeTest', result)
      //       .then(() => {
      //         if (result === 'test_result=2' || result === 'test_result=1') {
      //           router.push('/outcome/2')
      //         } else if (result === 'test_result=3') {
      //           router.push('/outcome/1')
      //         } else {
      //           router.push('/outcome/3')
      //         }
      //         if (btnMinimaze) {
      //           btnMinimaze.click()
      //         }
      //       })
      //   }
      // }
    }
    return []
  }
// eslint-disable-next-line func-call-spacing
})
console.log('V1.1.42')
